import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateUserGroupDTO from '../dto/createUserGroup';
import { Form, Formik, FormikProps } from 'formik';
import UserGroupFormValidation from '../validation/userGroupValidation';
import { Button, Checkbox, CheckboxGroup, Input } from '@nextui-org/react';
import { useState } from 'react';
import PermissionDTO from '@modules/userModule/permission/dto/permission.dto';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';

interface UserGroupFormProps {
  initialValues?: CreateUserGroupDTO;
  onSubmit: (values: CreateUserGroupDTO) => void | Promise<void>;
}

export const initialUserGroupFormValues: CreateUserGroupDTO = {
  name: '',
  default: false,
  permissions: [],
};

const UserGroupForm = ({
  initialValues = initialUserGroupFormValues,
  onSubmit,
}: UserGroupFormProps) => {
  const { t } = useTranslation();
  const [selectedEnterprisePermissions, setSelectedEnterprisePermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'ENTERPRISE')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'USER')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedUserGroupPermissions, setSelectedUserGroupPermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'USER_GROUP')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedTeamPermissions, setSelectedTeamPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'TEAM')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedPostPermissions, setSelectedPostPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'POST')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedPostCommentPermissions, setSelectedPostCommentPermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'POST_COMMENT')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedChallengePermissions, setSelectedChallengePermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'CHALLENGE')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [
    selectedChallengeAnswersPermissions,
    setSelectedChallengeAnswersPermissions,
  ] = useState<Array<PermissionDTO>>(
    initialValues.permissions
      .filter((permission) => permission.entity === 'CHALLENGE_ANSWER')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [
    selectedChallengeAnswerCommentPermissions,
    setSelectedChallengeAnswerCommentPermissions,
  ] = useState<Array<PermissionDTO>>(
    initialValues.permissions
      .filter((permission) => permission.entity === 'CHALLENGE_ANSWER_COMMENT')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedObjectivePermissions, setSelectedObjectivePermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'OBJECTIVE')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedKeyResultPermissions, setSelectedKeyResultPermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'KEY_RESULT')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedInitiativePermissions, setSelectedInitiativePermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'INITIATIVE')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedMetricPermissions, setSelectedMetricPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'METRIC')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedSurveyPermissions, setSelectedSurveyPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'SURVEY')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );
  const [selectedRecognitionPermissions, setSelectedRecognitionPermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'RECOGNITION')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );
  const [selectedThermometerPermissions, setSelectedThermometerPermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'THERMOMETER')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );

  const [selectedLicenseTypePermissions, setSelectedLicenseTypePermissions] =
    useState<Array<PermissionDTO>>(
      initialValues.permissions
        .filter((permission) => permission.entity === 'LICENSE_TYPE')
        .map((permission) => {
          return {
            ...permission,
          };
        }),
    );

  const [selectedLevelPermissions, setSelectedLevelPermissions] = useState<
    Array<PermissionDTO>
  >(
    initialValues.permissions
      .filter((permission) => permission.entity === 'LEVEL')
      .map((permission) => {
        return {
          ...permission,
        };
      }),
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserGroupFormValidation}
      onSubmit={(values) => {
        const enterprisePermissions = selectedEnterprisePermissions.map(
          (enterprisePermission) => {
            return {
              entity: 'ENTERPRISE',
              methods: enterprisePermission.methods,
            };
          },
        ) as PermissionDTO[];
        const userPermissions = selectedUserPermissions.map(
          (userPermission) => {
            return { entity: 'USER', methods: userPermission.methods };
          },
        ) as PermissionDTO[];
        const userGroupPermissions = selectedUserGroupPermissions.map(
          (userGroupPermission) => {
            return {
              entity: 'USER_GROUP',
              methods: userGroupPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const teamPermissions = selectedTeamPermissions.map(
          (teamPermission) => {
            return { entity: 'TEAM', methods: teamPermission.methods };
          },
        ) as PermissionDTO[];
        const postPermissions = selectedPostPermissions.map(
          (postPermission) => {
            return { entity: 'POST', methods: postPermission.methods };
          },
        ) as PermissionDTO[];
        const postCommentPermissions = selectedPostCommentPermissions.map(
          (postCommentPermission) => {
            return {
              entity: 'POST_COMMENT',
              methods: postCommentPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const challengePermissions = selectedChallengePermissions.map(
          (challengePermission) => {
            return {
              entity: 'CHALLENGE',
              methods: challengePermission.methods,
            };
          },
        ) as PermissionDTO[];
        const challengeAnswerPermissions =
          selectedChallengeAnswersPermissions.map(
            (challengeAnswerPermission) => {
              return {
                entity: 'CHALLENGE_ANSWER',
                methods: challengeAnswerPermission.methods,
              };
            },
          ) as PermissionDTO[];
        const challengeAnswerCommentPermissions =
          selectedChallengeAnswerCommentPermissions.map(
            (challengeAnswerCommentPermission) => {
              return {
                entity: 'CHALLENGE_ANSWER_COMMENT',
                methods: challengeAnswerCommentPermission.methods,
              };
            },
          ) as PermissionDTO[];
        const objectivePermissions = selectedObjectivePermissions.map(
          (objectivePermission) => {
            return {
              entity: 'OBJECTIVE',
              methods: objectivePermission.methods,
            };
          },
        ) as PermissionDTO[];
        const keyResultPermissions = selectedKeyResultPermissions.map(
          (keyResultPermission) => {
            return {
              entity: 'KEY_RESULT',
              methods: keyResultPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const initiativePermissions = selectedInitiativePermissions.map(
          (initiativePermission) => {
            return {
              entity: 'INITIATIVE',
              methods: initiativePermission.methods,
            };
          },
        ) as PermissionDTO[];
        const metricPermissions = selectedMetricPermissions.map(
          (metricPermission) => {
            return {
              entity: 'METRIC',
              methods: metricPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const surveyPermissions = selectedSurveyPermissions.map(
          (metricPermission) => {
            return {
              entity: 'SURVEY',
              methods: metricPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const recognitionPermissions = selectedRecognitionPermissions.map(
          (metricPermission) => {
            return {
              entity: 'RECOGNITION',
              methods: metricPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const thermometerPermissions = selectedThermometerPermissions.map(
          (metricPermission) => {
            return {
              entity: 'THERMOMETER',
              methods: metricPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const licenseTypePermissions = selectedLicenseTypePermissions.map(
          (metricPermission) => {
            return {
              entity: 'LICENSE_TYPE',
              methods: metricPermission.methods,
            };
          },
        ) as PermissionDTO[];
        const levelPermissions = selectedLevelPermissions.map(
          (levelPermission) => {
            return {
              entity: 'LEVEL',
              methods: levelPermission.methods,
            };
          },
        ) as PermissionDTO[];
        onSubmit({
          ...values,
          permissions: [
            ...enterprisePermissions,
            ...userPermissions,
            ...userGroupPermissions,
            ...teamPermissions,
            ...postPermissions,
            ...postCommentPermissions,
            ...challengePermissions,
            ...challengeAnswerPermissions,
            ...challengeAnswerCommentPermissions,
            ...objectivePermissions,
            ...keyResultPermissions,
            ...initiativePermissions,
            ...metricPermissions,
            ...surveyPermissions,
            ...recognitionPermissions,
            ...thermometerPermissions,
            ...licenseTypePermissions,
            ...levelPermissions,
          ],
        });
      }}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        values,
      }: FormikProps<CreateUserGroupDTO>) => (
        <Form className="py-5 flex flex-col gap-5">
          <div className="grid grid-cols-1 gap-5">
            <Input
              name="name"
              label={t('entities.userGroup.fields.name')}
              isInvalid={Boolean(errors.name)}
              errorMessage={errors.name}
              color={errors.name ? 'danger' : 'default'}
              onChange={(e) => setFieldValue('name', e.target.value)}
              value={values.name}
            />
          </div>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.ENTERPRISE',
            )}
            name="permissions"
            value={selectedEnterprisePermissions.map(
              (enterprisePermissions) => enterprisePermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const enterprisePermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedEnterprisePermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  enterprisePermissionCopy.push(
                    selectedEnterprisePermissions[existInPermission],
                  );
                } else {
                  enterprisePermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'ENTERPRISE' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedEnterprisePermissions(enterprisePermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.USER')}
            name="permissionsUser"
            value={selectedUserPermissions.map(
              (userPermissions) => userPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const userPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedUserPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  userPermissionCopy.push(
                    selectedUserPermissions[existInPermission],
                  );
                } else {
                  userPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['USER' as keyof typeof AllowedEntities],
                  });
                }
              });

              setSelectedUserPermissions(userPermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.USER_GROUP',
            )}
            name="permissionsUser"
            value={selectedUserGroupPermissions.map(
              (userGroupPermissions) => userGroupPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const userGroupPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedUserGroupPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  userGroupPermissionCopy.push(
                    selectedUserGroupPermissions[existInPermission],
                  );
                } else {
                  userGroupPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'USER_GROUP' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedUserGroupPermissions(userGroupPermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.TEAM')}
            name="permissionsUser"
            value={selectedTeamPermissions.map(
              (teamPermissions) => teamPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const teamPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedTeamPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  teamPermissionCopy.push(
                    selectedTeamPermissions[existInPermission],
                  );
                } else {
                  teamPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['TEAM' as keyof typeof AllowedEntities],
                  });
                }
              });
              setSelectedTeamPermissions(teamPermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.POST')}
            name="permissionsUser"
            value={selectedPostPermissions.map(
              (postPermissions) => postPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const postPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedPostPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  postPermissionCopy.push(
                    selectedPostPermissions[existInPermission],
                  );
                } else {
                  postPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['POST' as keyof typeof AllowedEntities],
                  });
                }
              });
              setSelectedPostPermissions(postPermissionCopy);
            }}
          >
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD_POST')}
            </Checkbox>
            <Checkbox value="PIN">
              {t('entities.userGroup.values.permissions.methods.PIN')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY_OWN')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE_OWN')}
            </Checkbox>
            <Checkbox value="MODIFY_OTHERS">
              {t('entities.userGroup.values.permissions.methods.MODIFY_OTHERS')}
            </Checkbox>
            <Checkbox value="DELETE_OTHERS">
              {t('entities.userGroup.values.permissions.methods.DELETE_OTHERS')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.POST_COMMENT',
            )}
            name="permissionsUser"
            value={selectedPostCommentPermissions.map(
              (postCommentPermissions) => postCommentPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const postCommentPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedPostCommentPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  postCommentPermissionCopy.push(
                    selectedPostCommentPermissions[existInPermission],
                  );
                } else {
                  postCommentPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'POST_COMMENT' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedPostCommentPermissions(postCommentPermissionCopy);
            }}
          >
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD_COMMENT')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OWN_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="DELETE">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OWN_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="MODIFY_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OTHERS_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="DELETE_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OTHERS_COMMENT',
              )}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.CHALLENGE',
            )}
            name="permissionsUser"
            value={selectedChallengePermissions.map(
              (challengePermissions) => challengePermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const challengePermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedChallengePermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  challengePermissionCopy.push(
                    selectedChallengePermissions[existInPermission],
                  );
                } else {
                  challengePermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'CHALLENGE' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedChallengePermissions(challengePermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t(
                'entities.userGroup.values.permissions.methods.PARTICIPATE_CHALLENGE',
              )}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD_CHALLENGE')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.CHALLENGE_ANSWER',
            )}
            name="permissionsUser"
            value={selectedChallengeAnswersPermissions.map(
              (challengeAnswerPermissions) =>
                challengeAnswerPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const challengeAnswerPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedChallengeAnswersPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  challengeAnswerPermissionCopy.push(
                    selectedChallengeAnswersPermissions[existInPermission],
                  );
                } else {
                  challengeAnswerPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'CHALLENGE_ANSWER' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedChallengeAnswersPermissions(
                challengeAnswerPermissionCopy,
              );
            }}
          >
            <Checkbox value="MODIFY">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OWN_CHALLENGE_ANSWER',
              )}
            </Checkbox>
            <Checkbox value="DELETE">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OWN_CHALLENGE_ANSWER',
              )}
            </Checkbox>
            <Checkbox value="MODIFY_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OTHERS_CHALLENGE_ANSWER',
              )}
            </Checkbox>
            <Checkbox value="DELETE_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OTHERS_CHALLENGE_ANSWER',
              )}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.CHALLENGE_ANSWER_COMMENT',
            )}
            name="permissionsUser"
            value={selectedChallengeAnswerCommentPermissions.map(
              (challengeAnswerCommentPermissions) =>
                challengeAnswerCommentPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const challengeAnswerCommentPermissionCopy: Array<PermissionDTO> =
                [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedChallengeAnswerCommentPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  challengeAnswerCommentPermissionCopy.push(
                    selectedChallengeAnswerCommentPermissions[
                      existInPermission
                    ],
                  );
                } else {
                  challengeAnswerCommentPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'CHALLENGE_ANSWER_COMMENT' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedChallengeAnswerCommentPermissions(
                challengeAnswerCommentPermissionCopy,
              );
            }}
          >
            <Checkbox value="ADD">
              {t(
                'entities.userGroup.values.permissions.methods.ADD_COMMENT_CHALLENGE',
              )}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OWN_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="DELETE">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OWN_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="MODIFY_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_OTHERS_COMMENT',
              )}
            </Checkbox>
            <Checkbox value="DELETE_OTHERS">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_OTHERS_COMMENT',
              )}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.OBJECTIVE',
            )}
            name="permissionsObjective"
            value={selectedObjectivePermissions.map(
              (objectivePermissions) => objectivePermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const objectivePermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedObjectivePermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  objectivePermissionCopy.push(
                    selectedObjectivePermissions[existInPermission],
                  );
                } else {
                  objectivePermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'OBJECTIVE' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedObjectivePermissions(objectivePermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.KEY_RESULT',
            )}
            name="permissionsKeyResult"
            value={selectedKeyResultPermissions.map(
              (keyResultPermissions) => keyResultPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const keyResultPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedKeyResultPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  keyResultPermissionCopy.push(
                    selectedKeyResultPermissions[existInPermission],
                  );
                } else {
                  keyResultPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'KEY_RESULT' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedKeyResultPermissions(keyResultPermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.INITIATIVE',
            )}
            name="permissionsInitiative"
            value={selectedInitiativePermissions.map(
              (initiativePermissions) => initiativePermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const initiativePermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedInitiativePermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  initiativePermissionCopy.push(
                    selectedInitiativePermissions[existInPermission],
                  );
                } else {
                  initiativePermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'INITIATIVE' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedInitiativePermissions(initiativePermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.THERMOMETER',
            )}
            name="permissionsThermometer"
            value={selectedThermometerPermissions.map(
              (surveyPermissions) => surveyPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const thermometerPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedThermometerPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  thermometerPermissionCopy.push(
                    selectedThermometerPermissions[existInPermission],
                  );
                } else {
                  thermometerPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'THERMOMETER' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedThermometerPermissions(thermometerPermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.ANSWER')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.CONFIGURE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.SURVEY')}
            name="permissionsSurvey"
            value={selectedSurveyPermissions.map(
              (surveyPermissions) => surveyPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const surveyPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedSurveyPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  surveyPermissionCopy.push(
                    selectedSurveyPermissions[existInPermission],
                  );
                } else {
                  surveyPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['SURVEY' as keyof typeof AllowedEntities],
                  });
                }
              });
              setSelectedSurveyPermissions(surveyPermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t(
                'entities.userGroup.values.permissions.methods.PARTICIPATE_SURVEY',
              )}
            </Checkbox>
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.RECOGNITION',
            )}
            name="permissionsRecognition"
            value={selectedRecognitionPermissions.map(
              (surveyPermissions) => surveyPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const recognitionPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedRecognitionPermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  recognitionPermissionCopy.push(
                    selectedRecognitionPermissions[existInPermission],
                  );
                } else {
                  recognitionPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'RECOGNITION' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedRecognitionPermissions(recognitionPermissionCopy);
            }}
          >
            <Checkbox value="ADMIN">
              {t('entities.userGroup.values.permissions.methods.FREE_ROCKETS')}
            </Checkbox>
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.RECOGNIZE')}
            </Checkbox>
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST_STRENGTH')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t(
                'entities.userGroup.values.permissions.methods.MODIFY_STRENGTH',
              )}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD_STRENGTH')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t(
                'entities.userGroup.values.permissions.methods.DELETE_STRENGTH',
              )}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.METRIC')}
            name="permissions"
            value={selectedMetricPermissions.map(
              (enterprisePermissions) => enterprisePermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const metricPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedMetricPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  metricPermissionCopy.push(
                    selectedMetricPermissions[existInPermission],
                  );
                } else {
                  metricPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['METRIC' as keyof typeof AllowedEntities],
                  });
                }
              });
              setSelectedMetricPermissions(metricPermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t(
              'entities.userGroup.values.permissions.entities.LICENSE_TYPE',
            )}
            name="permissionsLicenseType"
            value={selectedLicenseTypePermissions.map(
              (licenseTypePermissions) => {
                return licenseTypePermissions.methods;
              },
            )}
            onValueChange={(availableMethods) => {
              const licenseTypePermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission =
                  selectedLicenseTypePermissions.findIndex(
                    (permission) => permission.methods === availableMethod,
                  );
                if (existInPermission >= 0) {
                  licenseTypePermissionCopy.push(
                    selectedLicenseTypePermissions[existInPermission],
                  );
                } else {
                  licenseTypePermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities[
                        'LICENSE_TYPE' as keyof typeof AllowedEntities
                      ],
                  });
                }
              });
              setSelectedLicenseTypePermissions(licenseTypePermissionCopy);
            }}
          >
            <Checkbox value="LIST">
              {t('entities.userGroup.values.permissions.methods.LIST')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
            <Checkbox value="ADMIN">
              {t('entities.userGroup.values.permissions.methods.ADMIN')}
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup
            label={t('entities.userGroup.values.permissions.entities.LEVEL')}
            name="permissionsUser"
            value={selectedLevelPermissions.map(
              (levelPermissions) => levelPermissions.methods,
            )}
            onValueChange={(availableMethods) => {
              const levelPermissionCopy: Array<PermissionDTO> = [];
              availableMethods.map((availableMethod) => {
                const existInPermission = selectedLevelPermissions.findIndex(
                  (permission) => permission.methods === availableMethod,
                );
                if (existInPermission >= 0) {
                  levelPermissionCopy.push(
                    selectedLevelPermissions[existInPermission],
                  );
                } else {
                  levelPermissionCopy.push({
                    methods:
                      AllowedMethods[
                        availableMethod as keyof typeof AllowedMethods
                      ],
                    entity:
                      AllowedEntities['LEVEL' as keyof typeof AllowedEntities],
                  });
                }
              });
              setSelectedLevelPermissions(levelPermissionCopy);
            }}
          >
            <Checkbox value="VIEW">
              {t('entities.userGroup.values.permissions.methods.VIEW')}
            </Checkbox>
            <Checkbox value="ADD">
              {t('entities.userGroup.values.permissions.methods.ADD')}
            </Checkbox>
            <Checkbox value="MODIFY">
              {t('entities.userGroup.values.permissions.methods.MODIFY')}
            </Checkbox>
            <Checkbox value="DELETE">
              {t('entities.userGroup.values.permissions.methods.DELETE')}
            </Checkbox>
          </CheckboxGroup>
          <Button type="submit" color="primary" isLoading={isSubmitting}>
            {t('signup.form.finish')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserGroupForm;
