import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Select,
  SelectItem,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';
import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import UserDTO from '@modules/userModule/user/dto/user.dto';
import UserRepository from '@modules/userModule/user/user.repository';
import DateUtil from '@utils/date';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import ChallengesRepository from '@modules/voteModule/challenges/challenges.repository';
import HistoryEventDTO from '../dto/historyEvent.dto';
import DocumentRepository from '@modules/documentModule/document.repository';
import PulseSurveyRepository from '@modules/voteModule/pulse/pulseSurvey/pulseSurvey.repository';
import LicenseRequestRepository from '@modules/licenseModule/licenseRequest/licenseRequest.repository';
import LevelRepository from '@modules/userModule/level/level.repository';
import ObjectiveRepository from '@modules/objectiveModule/objective/objective.repository';
import PostsRepository from '@modules/feed/posts/posts.repository';
import RecognitionVoteRepository from '@modules/voteModule/recognition/recognitionVote/recognitionVote.repository';
import ThermometerRepository from '@modules/voteModule/thermometerVote/thermometer.repository';
import HistoryRepository from '../history.repository';

const HistoryMetric = () => {
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;

  const [teamRepository] = useState(new TeamRepository());
  const [teams, setTeams] = useState<TeamDTO[]>([]);
  const [userRepository] = useState(new UserRepository());
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [challengeRepository] = useState(new ChallengesRepository());
  const [documentRepository] = useState(new DocumentRepository());
  const [pulseRepository] = useState(new PulseSurveyRepository());
  const [licenseRepository] = useState(new LicenseRequestRepository());
  const [levelRepository] = useState(new LevelRepository());
  const [objectiveRepository] = useState(new ObjectiveRepository());
  const [feedRepository] = useState(new PostsRepository());
  const [recognitionRepository] = useState(new RecognitionVoteRepository());
  const [thermometerRepository] = useState(new ThermometerRepository());
  const [historyRepository] = useState(new HistoryRepository());
  const [events, setEvents] = useState<HistoryEventDTO[]>([]);
  const [order, setOrder] = useState<number>(-1);
  const [orderQuery, setOrderQuery] = useState<{
    createdDate: 'ASC' | 'DESC';
    lastModifiedDate: 'ASC' | 'DESC';
  }>({
    createdDate: 'DESC',
    lastModifiedDate: 'DESC',
  });
  const [userIdMap] = useState<Map<string, UserDTO>>(
    new Map<string, UserDTO>(),
  );
  const [userEmailMap] = useState<Map<string, UserDTO>>(
    new Map<string, UserDTO>(),
  );
  const [moduleMap] = useState<Map<string, boolean>>(
    new Map<string, boolean>(),
  );
  const [selectedModules, setSelectedModules] = useState<string | null>(null);

  let selectedUserIds: string | null = null;
  let selectedUserEmails: string | null = null;
  let take: number | undefined;

  const periodDates = DateUtil.quarterToDate(period);

  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [today] = useState<string>(new Date().toISOString().substring(0, 10));

  const loadMore = () => {
    setIsLoading(true);
    if (events.length < PAGINATION_TAKE) setHasMore(false);
    take = take ? take + PAGINATION_TAKE : PAGINATION_TAKE;
    getHistory();
  };

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: loadMore,
  });

  const handleSelectionTeamChange = async (e: {
    target: { value: string };
  }) => {
    setIsLoading(true);
    selectModules();
    let params;
    if (e.target.value != '') {
      params = {
        where: { team: { id: `in=${e.target.value}` } },
      };
    }
    const usersResult = await userRepository.find(params);
    setUsers(usersResult.results);
    if (e.target.value != '') {
      let userIds = '';
      let userEmails = '';
      if (usersResult.results.length > 0) {
        userIdMap.clear();
        userEmailMap.clear();
        usersResult.results.forEach((user) => {
          userIds = userIds + user.id + ',';
          userEmails = userEmails + user.email + ',';
          userIdMap.set(user.id, user);
          userEmailMap.set(user.email, user);
        });
        userIds = userIds.substring(0, userIds.length - 1);
      }
      selectedUserIds = userIds;
      selectedUserEmails = userEmails;
    }
    getHistory();
  };

  const handleSelectionUserChange = async (e: {
    target: { value: string };
  }) => {
    setIsLoading(true);
    selectModules();
    selectedUserEmails = null;
    if (e.target.value != '') {
      e.target.value.split(',').forEach((element) => {
        const user = userIdMap?.get(element);
        if (user)
          if (selectedUserEmails)
            selectedUserEmails = selectedUserEmails + ',' + user.email;
          else selectedUserEmails = user.email;
      });
      selectedUserIds = e.target.value;
    }
    getHistory();
  };

  const handleSelectionModuleChange = async (e: {
    target: { value: string };
  }) => {
    setIsLoading(true);
    setSelectedModules(e.target.value);
    selectModules(e.target.value);
    getHistory();
  };

  const handleOrderChange = async (e: { target: { value: string } }) => {
    setOrder(Number.parseInt(e.target.value));
  };

  const selectModules = async (modules?: string) => {
    if (modules) {
      moduleMap.set('challenge', false);
      moduleMap.set('document', false);
      moduleMap.set('survey', false);
      moduleMap.set('license', false);
      moduleMap.set('level', false);
      moduleMap.set('objective', false);
      moduleMap.set('feed', false);
      moduleMap.set('recognition', false);
      moduleMap.set('thermometer', false);
      modules.split(',').forEach((module) => {
        moduleMap.set(module, true);
      });
    } else if (modules != '' && selectedModules) {
      moduleMap.set('challenge', false);
      moduleMap.set('document', false);
      moduleMap.set('survey', false);
      moduleMap.set('license', false);
      moduleMap.set('level', false);
      moduleMap.set('objective', false);
      moduleMap.set('feed', false);
      moduleMap.set('recognition', false);
      moduleMap.set('thermometer', false);
      selectedModules.split(',').forEach((module) => {
        moduleMap.set(module, true);
      });
    } else {
      moduleMap.set('challenge', true);
      moduleMap.set('document', true);
      moduleMap.set('survey', true);
      moduleMap.set('license', true);
      moduleMap.set('level', true);
      moduleMap.set('objective', true);
      moduleMap.set('feed', true);
      moduleMap.set('recognition', true);
      moduleMap.set('thermometer', true);
    }
  };

  const getHistory = async () => {
    if (teams.length == 0 && users.length == 0) {
      const teamsResult = await teamRepository.find();
      setTeams(teamsResult.results);
      const usersResult = await userRepository.find();
      setUsers(usersResult.results);
      userIdMap.clear();
      userEmailMap.clear();
      usersResult.results.forEach((user) => {
        userIdMap.set(user.id, user);
        userEmailMap.set(user.email, user);
      });
    }
    setEvents([]);
    if (selectedUserIds != '') {
      let challengeResult: HistoryEventDTO[] = [];
      const dateFrom = periodDates.from.toISOString().substring(0, 10);
      const dateTo = periodDates.to.toISOString().substring(0, 10);
      if (moduleMap.get('challenge'))
        challengeResult = await getChallengesHistory(dateFrom, dateTo);
      let documentResult: HistoryEventDTO[] = [];
      if (moduleMap.get('document'))
        documentResult = await getDocumentHistory(dateFrom, dateTo);
      let surveyResult: HistoryEventDTO[] = [];
      if (moduleMap.get('survey'))
        surveyResult = await getSurveyHistory(dateFrom, dateTo);
      let licenseResult: HistoryEventDTO[] = [];
      if (moduleMap.get('license'))
        licenseResult = await getLicenseHistory(dateFrom, dateTo);
      let levelResult: HistoryEventDTO[] = [];
      if (moduleMap.get('level'))
        levelResult = await getLevelHistory(dateFrom, dateTo);
      let objectiveResult: HistoryEventDTO[] = [];
      if (moduleMap.get('objective'))
        objectiveResult = await getObjectiveHistory(dateFrom, dateTo);
      let feedResult: HistoryEventDTO[] = [];
      if (moduleMap.get('feed'))
        feedResult = await getFeedHistory(dateFrom, dateTo);
      let recognitionResult: HistoryEventDTO[] = [];
      if (moduleMap.get('recognition'))
        recognitionResult = await getRecognitionHistory(dateFrom, dateTo);
      let thermometerResult: HistoryEventDTO[] = [];
      if (moduleMap.get('thermometer'))
        thermometerResult = await getThermometerHistory(dateFrom, dateTo);
      const events = [
        ...challengeResult,
        ...documentResult,
        ...surveyResult,
        ...licenseResult,
        ...levelResult,
        ...objectiveResult,
        ...feedResult,
        ...recognitionResult,
        ...thermometerResult,
      ];
      events.sort(eventSort);
      setEvents(events);
    }
    setIsLoading(false);
  };

  const eventSort = (eventA: HistoryEventDTO, eventB: HistoryEventDTO) => {
    return DateUtil.dateSortFunction(eventA.date, eventB.date) * order;
  };

  const getChallengesHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
      ];
    const challengeCreatedResult = await challengeRepository.get({
      where,
      take,
      order: orderQuery,
    });
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-[#3806A36B]';
    challengeCreatedResult.forEach((challenge) => {
      if (
        validateDate(challenge.createdDate, dateFrom, dateTo) &&
        validateUserEmail(challenge.createdBy)
      ) {
        events.push({
          id: challenge.id + ',' + challenge.createdDate,
          date: challenge.createdDate,
          collaborator: getUserFullNameFromEmail(challenge.createdBy),
          description: t('entities.metric.history.event.challenge.created'),
          color,
        });
      }
      if (
        validateDate(challenge.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(challenge.lastModifiedBy) &&
        challenge.lastModifiedDate.toString().substring(0, 19) != challenge.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: challenge.id + ',' + challenge.lastModifiedDate,
          date: challenge.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(challenge.lastModifiedBy),
          description: t('entities.metric.history.event.challenge.modified'),
          color,
        });
      }
    });
    return events;
  };

  const getDocumentHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
      ];
    const documentCreatedResult = await documentRepository.get({
      where,
      take,
      order: orderQuery,
    });
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-[#C5F2D9]';
    documentCreatedResult.forEach((document) => {
      if (
        validateDate(document.createdDate, dateFrom, dateTo) &&
        validateUserEmail(document.createdBy)
      ) {
        events.push({
          id: document.id + ',' + document.createdDate,
          date: document.createdDate,
          collaborator: getUserFullNameFromEmail(document.createdBy),
          description: t('entities.metric.history.event.document.created'),
          color,
        });
      }
      if (
        validateDate(document.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(document.lastModifiedBy) &&
        document.lastModifiedDate.toString().substring(0, 19) != document.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: document.id + ',' + document.lastModifiedDate,
          date: document.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(document.lastModifiedBy),
          description: t('entities.metric.history.event.document.modified'),
          color,
        });
      }
    });
    return events;
  };

  const getSurveyHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          votes: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
        {
          votes: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            createdBy: `in=${selectedUserEmails}`,
          },
        },
      ];
    const pulseResult = (
      await pulseRepository.find({
        where,
        relations: ['votes', 'votes.user'],
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-[#E8FAF0]';
    pulseResult.forEach((survey) => {
      if (
        validateDate(survey.createdDate, dateFrom, dateTo) &&
        validateUserEmail(survey.createdBy)
      ) {
        events.push({
          id: survey.id + ',' + survey.createdDate,
          date: survey.createdDate,
          collaborator: getUserFullNameFromEmail(survey.createdBy),
          description: t('entities.metric.history.event.survey.created'),
          color,
        });
      }
      if (
        validateDate(survey.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(survey.lastModifiedBy) &&
        survey.lastModifiedDate.toString().substring(0, 19) != survey.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: survey.id + ',' + survey.lastModifiedDate,
          date: survey.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(survey.lastModifiedBy),
          description: t('entities.metric.history.event.survey.modified'),
          color,
        });
      }
      survey.votes?.forEach((vote) => {
        if (
          validateDate(vote.createdDate, dateFrom, dateTo) &&
          validateUserEmail(vote.createdBy)
        ) {
          events.push({
            id: vote.id + ',' + vote.createdDate,
            date: vote.createdDate,
            collaborator: getUserFullNameFromEmail(vote.createdBy),
            description: t('entities.metric.history.event.survey.answered'),
            color,
          });
        }
      });
    });
    return events;
  };

  const getLicenseHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          startDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          endDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
        {
          startDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          endDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
      ];
    const licenseCreatedResult = (
      await licenseRepository.find({
        where,
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-[#b3b3ff]';
    licenseCreatedResult.forEach((license) => {
      if (validateUserEmail(license.createdBy)) {
        if (validateDate(license.createdDate, dateFrom, dateTo)) {
          events.push({
            id: license.id + ',' + license.createdDate + ',created',
            date: license.createdDate,
            collaborator: getUserFullNameFromEmail(license.createdBy),
            description: t('entities.metric.history.event.license.created'),
            color,
          });
        }
        if (validateDate(license.startDate, dateFrom, dateTo)) {
          events.push({
            id: license.id + ',' + license.startDate + ',initiated',
            date: license.startDate,
            collaborator: getUserFullNameFromEmail(license.createdBy),
            description: t('entities.metric.history.event.license.start'),
            color,
          });
        }
        if (validateDate(license.endDate, dateFrom, dateTo)) {
          events.push({
            id: license.id + ',' + license.endDate + ',finished',
            date: license.endDate,
            collaborator: getUserFullNameFromEmail(license.createdBy),
            description: t('entities.metric.history.event.license.end'),
            color,
          });
        }
      }
      if (
        validateDate(license.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(license.lastModifiedBy) &&
        license.lastModifiedDate.toString().substring(0, 19) != license.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: license.id + ',' + license.lastModifiedDate + ',modified',
          date: license.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(license.lastModifiedBy),
          description: t('entities.metric.history.event.license.modified'),
          color,
        });
      }
    });
    return events;
  };

  const getLevelHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: '!=system',
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: '!=system',
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
      ];
    const levelCreatedResult = (
      await levelRepository.find({
        where,
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-content3';
    levelCreatedResult.forEach((level) => {
      if (
        validateDate(level.createdDate, dateFrom, dateTo) &&
        validateUserEmail(level.createdBy)
      ) {
        events.push({
          id: level.id + ',' + level.createdDate,
          date: level.createdDate,
          collaborator: getUserFullNameFromEmail(level.createdBy),
          description: t('entities.metric.history.event.level.created'),
          color,
        });
      }
      if (
        validateDate(level.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(level.lastModifiedBy) &&
        level.lastModifiedDate.toString().substring(0, 19) != level.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: level.id + ',' + level.lastModifiedDate,
          date: level.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(level.lastModifiedBy),
          description: t('entities.metric.history.event.level.modified'),
          color,
        });
      }
    });
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          level: { id: '!=null' },
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
          level: { id: '!=null' },
        },
      ];
    const levelHistoryResult = (
      await historyRepository.find({
        where,
        take,
        order: orderQuery,
        relations: ['user', 'level'],
      })
    ).results;
    levelHistoryResult.forEach((levelHistory) => {
      if (
        validateDate(levelHistory.createdDate, dateFrom, dateTo) &&
        validateUserEmail(levelHistory.createdBy)
      ) {
        events.push({
          id: levelHistory.id + ',' + levelHistory.createdDate,
          date: levelHistory.createdDate,
          collaborator: getUserFullNameFromEmail(levelHistory.createdBy),
          description: t('entities.metric.history.event.level.achieved', {
            number: levelHistory.level.number,
          }),
          color,
        });
      }
    });
    return events;
  };

  const getObjectiveHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          keyResults: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          keyResults: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          keyResults: {
            periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          keyResults: {
            periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          comments: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          comments: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
        {
          periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          manager: {
            externalUser: `in=${selectedUserIds}`,
          },
        },
        {
          periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          manager: {
            externalUser: `in=${selectedUserIds}`,
          },
        },
        {
          keyResults: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            createdBy: `in=${selectedUserEmails}`,
          },
        },
        {
          keyResults: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            lastModifiedBy: `in=${selectedUserEmails}`,
          },
        },
        {
          keyResults: {
            periodFrom: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            manager: {
              externalUser: `in=${selectedUserIds}`,
            },
          },
        },
        {
          keyResults: {
            periodTo: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            manager: {
              externalUser: `in=${selectedUserIds}`,
            },
          },
        },
        {
          comments: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            createdBy: `in=${selectedUserEmails}`,
          },
        },
        {
          comments: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            lastModifiedBy: `in=${selectedUserEmails}`,
          },
        },
      ];
    const objectiveCreatedResult = (
      await objectiveRepository.find({
        where,
        relations: ['comments', 'keyResults', 'manager', 'keyResults.manager'],
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-warning/20';
    //Objectives
    objectiveCreatedResult.forEach((objective) => {
      //Objective creation
      if (
        validateDate(objective.createdDate, dateFrom, dateTo) &&
        validateUserEmail(objective.createdBy)
      ) {
        events.push({
          id: objective.id + ',' + objective.createdDate + ',created',
          date: objective.createdDate,
          collaborator: getUserFullNameFromEmail(objective.createdBy),
          description: t('entities.metric.history.event.objective.created'),
          color,
        });
      }
      //Objective modified
      if (
        validateDate(objective.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(objective.lastModifiedBy) &&
        objective.lastModifiedDate.toString().substring(0, 19) != objective.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: objective.id + ',' + objective.lastModifiedDate + ',modified',
          date: objective.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(objective.lastModifiedBy),
          description: t('entities.metric.history.event.objective.modified'),
          color,
        });
      }
      //Objective initiated
      if (validateUserId(objective.manager.externalUser)) {
        if (
          validateDate(objective.periodFrom.toISOString(), dateFrom, dateTo)
        ) {
          events.push({
            id: objective.id + ',' + objective.periodFrom + ',initiated',
            date: objective.periodFrom,
            collaborator: getUserFullNameFromEmail(objective.manager.email),
            description: t('entities.metric.history.event.objective.start'),
            color,
          });
        }
        //Objective finished
        if (validateDate(objective.periodTo.toISOString(), dateFrom, dateTo)) {
          events.push({
            id: objective.id + ',' + objective.periodTo + ',finished',
            date: objective.periodTo,
            collaborator: getUserFullNameFromEmail(objective.manager.email),
            description: t('entities.metric.history.event.objective.end'),
            color,
          });
        }
      }
      //Key Results
      objective.keyResults.forEach((keyResult) => {
        //Key Result created
        if (
          validateDate(keyResult.createdDate, dateFrom, dateTo) &&
          validateUserEmail(keyResult.createdBy)
        ) {
          events.push({
            id: keyResult.id + ',' + keyResult.createdDate + ',created',
            date: keyResult.createdDate,
            collaborator: getUserFullNameFromEmail(keyResult.createdBy),
            description: t('entities.metric.history.event.kr.created'),
            color,
          });
        }
        //Key Result modified
        if (
          validateDate(keyResult.lastModifiedDate, dateFrom, dateTo) &&
          validateUserEmail(keyResult.lastModifiedBy) &&
          keyResult.lastModifiedDate.toString().substring(0, 19) != keyResult.createdDate.toString().substring(0, 19)
        ) {
          events.push({
            id: keyResult.id + ',' + keyResult.lastModifiedDate + ',modified',
            date: keyResult.lastModifiedDate,
            collaborator: getUserFullNameFromEmail(keyResult.lastModifiedBy),
            description: t('entities.metric.history.event.kr.modified'),
            color,
          });
        }
        //Key Result initiated
        if (validateUserId(keyResult.manager.externalUser)) {
          if (validateDate(keyResult.periodFrom, dateFrom, dateTo)) {
            events.push({
              id: keyResult.id + ',' + keyResult.periodFrom + ',initiated',
              date: keyResult.periodFrom,
              collaborator: getUserFullNameFromEmail(keyResult.manager.email),
              description: t('entities.metric.history.event.kr.start'),
              color,
            });
          }
          //Key Result finished
          if (validateDate(keyResult.periodTo, dateFrom, dateTo)) {
            events.push({
              id: keyResult.id + ',' + keyResult.periodTo + ',finished',
              date: keyResult.periodTo,
              collaborator: getUserFullNameFromEmail(keyResult.manager.email),
              description: t('entities.metric.history.event.kr.end'),
              color,
            });
          }
        }
      });
      //Comments
      objective.comments?.forEach((comment) => {
        //Comment created
        if (
          validateDate(comment.createdDate, dateFrom, dateTo) &&
          validateUserEmail(comment.createdBy)
        ) {
          events.push({
            id: comment.id + ',' + comment.createdDate,
            date: comment.createdDate,
            collaborator: getUserFullNameFromEmail(comment.createdBy),
            description: t('entities.metric.history.event.comment.created'),
            color,
          });
        }
        //Comment modified
        if (
          validateDate(comment.lastModifiedDate, dateFrom, dateTo) &&
          validateUserEmail(comment.lastModifiedBy) &&
          comment.lastModifiedDate.toString().substring(0, 19) != comment.createdDate.toString().substring(0, 19)
        ) {
          events.push({
            id: comment.id + ',' + comment.lastModifiedDate,
            date: comment.lastModifiedDate,
            collaborator: getUserFullNameFromEmail(comment.lastModifiedBy),
            description: t('entities.metric.history.event.comment.modified'),
            color,
          });
        }
      });
    });
    return events;
  };

  const getFeedHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
        {
          comments: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          comments: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
        {
          interactions: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          },
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
        {
          lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          lastModifiedBy: `in=${selectedUserEmails}`,
        },
        {
          comments: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            createdBy: `in=${selectedUserEmails}`,
          },
        },
        {
          comments: {
            lastModifiedDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            lastModifiedBy: `in=${selectedUserEmails}`,
          },
        },
        {
          interactions: {
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
            createdBy: `in=${selectedUserEmails}`,
          },
        },
      ];
    const feedCreatedResult = (
      await feedRepository.getPosts({
        where,
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-secondary/20';
    //Posts
    feedCreatedResult.forEach((feed) => {
      //Post creation
      if (
        validateDate(feed.createdDate, dateFrom, dateTo) &&
        validateUserEmail(feed.createdBy)
      ) {
        events.push({
          id: feed.id + ',' + feed.createdDate + ',created',
          date: feed.createdDate,
          collaborator: getUserFullNameFromEmail(feed.createdBy),
          description: t('entities.metric.history.event.publication.created'),
          color,
        });
      }
      //Post modified
      if (
        validateDate(feed.lastModifiedDate, dateFrom, dateTo) &&
        validateUserEmail(feed.lastModifiedBy) &&
        feed.lastModifiedDate.toString().substring(0, 19) != feed.createdDate.toString().substring(0, 19)
      ) {
        events.push({
          id: feed.id + ',' + feed.lastModifiedDate + ',modified',
          date: feed.lastModifiedDate,
          collaborator: getUserFullNameFromEmail(feed.lastModifiedBy),
          description: t('entities.metric.history.event.publication.modified'),
          color,
        });
      }
      //Comments
      feed.comments?.forEach((comment) => {
        //Comment created
        if (
          validateDate(comment.createdDate, dateFrom, dateTo) &&
          validateUserEmail(comment.createdBy)
        ) {
          events.push({
            id: comment.id + ',' + comment.createdDate + ',created',
            date: comment.createdDate,
            collaborator: getUserFullNameFromEmail(comment.createdBy),
            description: t('entities.metric.history.event.comment.created'),
            color,
          });
        }
        //Comment modified
        if (
          validateDate(comment.lastModifiedDate, dateFrom, dateTo) &&
          validateUserEmail(comment.lastModifiedBy) &&
          comment.lastModifiedDate.toString().substring(0, 19) != comment.createdDate.toString().substring(0, 19)
        ) {
          events.push({
            id: comment.id + ',' + comment.lastModifiedDate + ',modified',
            date: comment.lastModifiedDate,
            collaborator: getUserFullNameFromEmail(comment.lastModifiedBy),
            description: t('entities.metric.history.event.comment.modified'),
            color,
          });
        }
      });
      //Interaction
      feed.interactions?.forEach((interaction) => {
        //Interaction created
        if (
          validateDate(interaction.createdDate, dateFrom, dateTo) &&
          validateUserEmail(interaction.createdBy)
        ) {
          events.push({
            id: interaction.id + ',' + interaction.createdDate,
            date: interaction.createdDate,
            collaborator: getUserFullNameFromEmail(interaction.createdBy),
            description: t('entities.metric.history.event.interaction.created'),
            color,
          });
        }
      });
    });
    return events;
  };

  const getRecognitionHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
      ];
    const recognitionCreatedResult = (
      await recognitionRepository.find({
        where,
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-primary-100';
    recognitionCreatedResult.forEach((recognition) => {
      if (
        validateDate(recognition.createdDate, dateFrom, dateTo) &&
        validateUserEmail(recognition.createdBy)
      ) {
        events.push({
          id: recognition.id + ',' + recognition.createdDate,
          date: recognition.createdDate,
          collaborator: getUserFullNameFromEmail(recognition.createdBy),
          description: t('entities.metric.history.event.recognition.sent'),
          color,
        });
      }
    });
    return events;
  };

  const getThermometerHistory = async (dateFrom: string, dateTo: string) => {
    let where = [];
    if (!selectedUserIds)
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
        },
      ];
    else
      where = [
        {
          createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          createdBy: `in=${selectedUserEmails}`,
        },
      ];
    const thermometerCreatedResult = (
      await thermometerRepository.find({
        where,
        take,
        order: orderQuery,
      })
    ).results;
    const events: HistoryEventDTO[] = [];
    const color: string = 'bg-danger-50';
    thermometerCreatedResult.forEach((thermometer) => {
      if (
        validateDate(thermometer.createdDate, dateFrom, dateTo) &&
        validateUserEmail(thermometer.createdBy)
      ) {
        events.push({
          id: thermometer.id + ',' + thermometer.createdDate,
          date: thermometer.createdDate,
          collaborator: getUserFullNameFromEmail(thermometer.createdBy),
          description: t('entities.metric.history.event.thermometer.answered'),
          color,
        });
      }
    });
    return events;
  };

  const getUserFullNameFromEmail = (email: string): string => {
    const user = userEmailMap?.get(email);
    return user ? user.firstName + ' ' + user.lastName : email;
  };

  const validateDate = (
    date: Date | string,
    dateFrom: string,
    dateTo: string,
  ): boolean => {
    const dateStr = date.toString().substring(0, 10);
    return dateStr >= dateFrom && dateTo >= dateStr && dateStr <= today;
  };

  const validateUserEmail = (email: string | null): boolean => {
    return (
      email != null &&
      (!selectedUserEmails ||
        email == selectedUserEmails ||
        selectedUserEmails.split(',').find((emailFound) => emailFound === email)
        ? true
        : false)
    );
  };

  const validateUserId = (id: string | null): boolean => {
    return (
      id != null &&
      (!selectedUserIds ||
        id == selectedUserIds ||
        selectedUserIds.split(',').find((idFound) => idFound === id)
        ? true
        : false)
    );
  };

  useEffect(() => {
    setIsLoading(true);
    selectModules();
    getHistory();
  }, [period]);

  useEffect(() => {
    setOrderQuery({
      createdDate: order == -1 ? 'DESC' : 'ASC',
      lastModifiedDate: order == -1 ? 'DESC' : 'ASC',
    });
    events.sort(eventSort);
  }, [order]);

  return (
    <Card className="shadow-none w-full">
      <CardHeader className="text-2xl">
        {<>{t('user.metric.tabs.history')}</>}
      </CardHeader>
      <CardBody>
        <div className="flex flex-col lg:flex-row gap-5">
          <Select
            className="min-w-[130px]"
            selectionMode="multiple"
            label={t('entities.metric.filter.area.values.team')}
            items={teams}
            onChange={handleSelectionTeamChange}
          >
            {(team) => <SelectItem key={team.id}>{team.name}</SelectItem>}
          </Select>
          <Select
            className="min-w-[130px]"
            selectionMode="multiple"
            label={t('entities.metric.filter.area.values.user')}
            items={users}
            onChange={handleSelectionUserChange}
            isDisabled={users.length < 1}
          >
            {(user) => (
              <SelectItem key={user.id}>
                {user.firstName + ' ' + user.lastName}
              </SelectItem>
            )}
          </Select>
          <Select
            className="min-w-[130px]"
            selectionMode="multiple"
            label={t('entities.metric.filter.area.values.module')}
            onChange={handleSelectionModuleChange}
          >
            <SelectItem key={'challenge'}>
              {t('entities.metric.filter.module.challenge')}
            </SelectItem>
            <SelectItem key={'document'}>
              {t('entities.metric.filter.module.document')}
            </SelectItem>
            <SelectItem key={'survey'}>
              {t('entities.metric.filter.module.survey')}
            </SelectItem>
            <SelectItem key={'license'}>
              {t('entities.metric.filter.module.license')}
            </SelectItem>
            <SelectItem key={'level'}>
              {t('entities.metric.filter.module.level')}
            </SelectItem>
            <SelectItem key={'objective'}>
              {t('entities.metric.filter.module.objective')}
            </SelectItem>
            <SelectItem key={'feed'}>
              {t('entities.metric.filter.module.feed')}
            </SelectItem>
            <SelectItem key={'recognition'}>
              {t('entities.metric.filter.module.recognition')}
            </SelectItem>
            <SelectItem key={'thermometer'}>
              {t('entities.metric.filter.module.thermometer')}
            </SelectItem>
          </Select>
          <Select
            className="min-w-[130px]"
            selectionMode="single"
            label={t('entities.metric.filter.area.values.order')}
            selectedKeys={[order]}
            onChange={handleOrderChange}
          >
            <SelectItem key={-1}>
              {t('entities.metric.filter.order.newerFirst')}
            </SelectItem>
            <SelectItem key={1}>
              {t('entities.metric.filter.order.olderFirst')}
            </SelectItem>
          </Select>
        </div>
      </CardBody>
      <CardBody>
        <Table
          baseRef={scrollerRef}
          bottomContent={
            hasMore ? (
              <div className="flex w-full justify-center">
                <Spinner ref={loaderRef} />
              </div>
            ) : null
          }
          classNames={{
            wrapper: 'shadow-none',
            base: 'max-h-[1080px] overflow-y-auto',
            table: 'mt-0 shadow-none',
            thead: '[&>tr]:first:border-b-0 [&>tr]:first:rounded-none border-0',
            tr: 'border-b-2 border-divider',
            th: 'text-primary bg-primary-50 font-normal text-md ',
          }}
        >
          <TableHeader>
            <TableColumn key={'date'}>
              {t('entities.metric.history.table.date')}
            </TableColumn>
            <TableColumn key={'collaborator'}>
              {t('entities.metric.history.table.collaborator')}
            </TableColumn>
            <TableColumn key={'description'}>
              {t('entities.metric.history.table.description')}
            </TableColumn>
          </TableHeader>
          <TableBody
            items={events}
            isLoading={isLoading}
            loadingContent={<Spinner />}
          >
            {(event) => (
              <TableRow key={event.id}>
                {(columnKey) => {
                  switch (columnKey) {
                    case 'date':
                      return (
                        <TableCell className={`text-nowrap ${event.color}`}>
                          {DateUtil.dateToFullDate(new Date(event.date))}
                        </TableCell>
                      );
                    case 'collaborator':
                      return (
                        <TableCell className={event.color}>
                          {event.collaborator}
                        </TableCell>
                      );
                    case 'description':
                      return (
                        <TableCell className={event.color}>
                          {event.description}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell>
                          <></>
                        </TableCell>
                      );
                  }
                }}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default HistoryMetric;
