import React from 'react';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LicenseRequestDTO from '../dto/licenseRequest.dto';
import LicenseRequestRepository from '../licenseRequest.repository';
import {
  Button,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import DateUtil from '@utils/date';
import { PAGINATION_TAKE } from '@lib/paginated/constants';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const LicenseRequestHistory = () => {
  const profile = useSelector(selectProfile);
  const { t } = useTranslation();

  const isLicenseAdmin = profile?.group.permissions.some(
    (permission) =>
      permission.entity === AllowedEntities.LICENSE_TYPE &&
      permission.methods === AllowedMethods.ADMIN,
  );
  const isLeader = profile?.teamLeader?.id;

  const [licenseRequests, setLicenseRequests] = useState<LicenseRequestDTO[]>(
    [],
  );
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getLicenseRequest = async (amount: number = PAGINATION_TAKE) => {
    setIsLoading(true);
    const where: any = {
      status: '!=PENDING',
    };

    if (!isLicenseAdmin && !isLeader)
      where['user'] = { externalUser: profile?.id };

    const response = await new LicenseRequestRepository().find({
      where,
      relations: ['licenseType', 'user'],
      order: {
        createdDate: 'DESC',
      },
      take: amount,
    });

    setHasMore(response.total > licenseRequests.length);
    setLicenseRequests(response.results);
    setIsLoading(false);
  };

  useEffect(() => {
    getLicenseRequest();
  }, []);

  const loadMore = () => {
    const newOffset = licenseRequests.length + PAGINATION_TAKE;
    getLicenseRequest(newOffset);
  };

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: loadMore,
  });

  const handleDeleteLicense = async (id: string) => {
    setSubmitting(true);
    await new LicenseRequestRepository().delete(id);
    getLicenseRequest();
    setSubmitting(false);
  };

  const [isSubmitting, setSubmitting] = useState(false);

  const getScreen = () => {
    if (Object.keys(licenseRequests).length === 0)
      return <div className="text-center">{t('user.license.list.empty')}</div>;

    return (
      <>
        <Table
          baseRef={scrollerRef}
          bottomContent={
            hasMore ? (
              <div className="flex w-full justify-center">
                <Spinner ref={loaderRef} />
              </div>
            ) : null
          }
          classNames={{
            wrapper: 'shadow-none',
            base: 'max-h-[520px] overflow-y-auto',
            table: 'mt-0 shadow-none',
            thead: '[&>tr]:first:border-b-0 [&>tr]:first:rounded-none border-0',
            tr: 'border-b-2 border-divider',
            th: 'text-primary bg-primary-50 font-normal text-md ',
          }}
        >
          <TableHeader>
            <TableColumn key={'dates'}>{t('user.license.history.table.date')}</TableColumn>
            <TableColumn key={'days'}>{t('user.license.history.table.days')}</TableColumn>
            <TableColumn key={'type'}>{t('user.license.history.table.type')}</TableColumn>
            <TableColumn key={'collaborator'}>{t('user.license.history.table.collaborator')}</TableColumn>
            <TableColumn key={'status'}>{t('user.license.history.table.status')}</TableColumn>
            <TableColumn key={'actions'}>
              <></>
            </TableColumn>
          </TableHeader>
          <TableBody
            items={licenseRequests}
            isLoading={isLoading}
            loadingContent={<Spinner />}
          >
            {(license) => (
              <TableRow key={license.id}>
                {(columnKey) => {
                  switch (columnKey) {
                    case 'dates':
                      return (
                        <TableCell className="text-nowrap">
                          {DateUtil.dateToPeriod(new Date(license.startDate))} -{' '}
                          {DateUtil.dateToPeriod(new Date(license.endDate))}
                        </TableCell>
                      );
                    case 'days':
                      return (
                        <TableCell>
                          {DateUtil.dateDaysDiff(
                            new Date(license.startDate),
                            new Date(license.endDate),
                          )}
                        </TableCell>
                      );
                    case 'type':
                      return <TableCell>{license.licenseType.name}</TableCell>;
                    case 'collaborator':
                      return <TableCell>{license.user.fullName}</TableCell>;
                    case 'status':
                      return (
                        <TableCell>
                          {t(
                            `entities.license.values.status.${license.status}`,
                          )}
                        </TableCell>
                      );
                    case 'actions':
                      return (
                        <TableCell>
                          {isLicenseAdmin && (
                            <Button
                              color="danger"
                              isLoading={isSubmitting}
                              onClick={() => handleDeleteLicense(license.id)}
                              className="min-w-4 text-[12px]"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </TableCell>
                      );
                    default:
                      return (
                        <TableCell>
                          <></>
                        </TableCell>
                      );
                  }
                }}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  };

  return <div>{getScreen()}</div>;
};

export default LicenseRequestHistory;
