/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TeamDTO from '@modules/userModule/team/dto/team.dto';
import TeamRepository from '@modules/userModule/team/team.repository';
import { Card, CardBody, CardHeader, Chip } from '@nextui-org/react';
import PulseSurveyMetric from '../pulseSurvey/components/pulseSurveyMetric';
import DateUtil from '@utils/date';
import ThermometerSurveyMetric from '../thermometerSurvey/components/thermometerSurveyMetric';
import SatisfactionSurveyMetric from './satisfactionCard';
import ParticipationMetric from './participation';
import ThermometerVotesMetric from '../thermometerSurvey/components/thermometerVotesMetric';
import PulseSurveyVoteMetric from '../pulseSurvey/components/pulseSurveyVoteMetric';
import PulseHeatMap from './heatmap';
import { useMetricFiltersContext } from '@modules/metricModule/context/metricFilters.context';

const SurveyMetric = () => {
  const { t } = useTranslation();
  const { filters } = useMetricFiltersContext();
  const period = filters.quarter;
  const [area] = useState<'enterprise' | 'team'>('enterprise');

  const [teams, setTeams] = useState<TeamDTO[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);

  const periodDates = DateUtil.quarterToDate(period);

  const getTeams = async () => {
    const teamsResult = await new TeamRepository().find({ take: 25 });
    setTeams(teamsResult.results);

    if (teamsResult.results.length > 0)
      setSelectedTeam(teamsResult.results[0].id);
  };

  useEffect(() => {
    if (area === 'enterprise') getTeams();
  }, [area]);

  return (
    <div className="flex flex-col lg:flex-row gap-5">
      <Card className="shadow-none w-full lg:w-1/4 h-fit pb-5">
        <CardBody>
          <ParticipationMetric period={period} />
        </CardBody>
      </Card>
      <Card className="shadow-none w-full lg:w-3/4 pb-5">
        <CardHeader className="text-2xl">
          {t('entities.metric.surveys')}
        </CardHeader>
        <CardBody>
          <div className="flex gap-3 overflow-x-auto py-5 px-5">
            <PulseSurveyMetric
              className="min-w-[240px] md:w-1/3 shadow-md"
              options={{
                dateFrom: `>=${DateUtil.dateForInput(periodDates.from)}`,
                dateTo: `<=${DateUtil.dateForInput(periodDates.to)}`,
              }}
            />
            <ThermometerSurveyMetric
              className="min-w-[240px] md:w-1/3 shadow-md"
              options={{
                createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
              }}
            />
            <SatisfactionSurveyMetric
              className="min-w-[240px] md:w-1/3 shadow-md"
              options={{
                from: `>=${DateUtil.dateForInput(periodDates.from)}`,
                to: `<=${DateUtil.dateForInput(periodDates.to)}`,
                bt: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
              }}
            />
          </div>
          <PulseHeatMap period={period} />
          <div className="pt-5">
            <div className="flex flex-col">
              <span className="text-lg font-bold">
                {t('entities.metric.responses.title')}
              </span>
              <span className="text-default-400 text-sm">
                {t('entities.metric.responses.description')}
              </span>
            </div>
            <div>
              <ThermometerVotesMetric />
            </div>
            <div>
              <PulseSurveyVoteMetric />
            </div>
          </div>
        </CardBody>
      </Card>
      {/* <h3>{t('user.metric.tabs.survey')}</h3>
      {area === 'team' && (
        <div className={`flex gap-3 pb-5`}>
          {teams.map((team) => (
            <Chip
              key={team.id}
              className="cursor-pointer"
              onClick={() => setSelectedTeam(team.id)}
              color={selectedTeam === team.id ? 'primary' : 'default'}
            >
              {team.name}
            </Chip>
          ))}
        </div>
      )}
      <div className="flex gap-3 overflow-x-auto py-5 px-5">
        <PulseSurveyMetric
          className="min-w-[240px] md:w-1/3"
          options={{
            dateFrom: `>=${DateUtil.dateForInput(periodDates.from)}`,
            dateTo: `<=${DateUtil.dateForInput(periodDates.to)}`,
          }}
        />
        <ThermometerSurveyMetric
          className="min-w-[240px] md:w-1/3"
          options={{
            createdDate: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          }}
        />
        <SatisfactionSurveyMetric
          className="min-w-[240px] md:w-1/3"
          options={{
            from: `>=${DateUtil.dateForInput(periodDates.from)}`,
            to: `<=${DateUtil.dateForInput(periodDates.to)}`,
            bt: `bt=${DateUtil.dateForInput(periodDates.from)},${DateUtil.dateForInput(periodDates.to)}`,
          }}
        />
      </div>
      <div>
        <div>
          <ParticipationMetric period={period} />
        </div>
        <div>
          <PulseHeatMap period={period} />
        </div>
        <div className="pt-5">
          <div className="flex flex-col">
            <span className="text-lg font-bold">
              {t('entities.metric.responses.title')}
            </span>
            <span>{t('entities.metric.responses.description')}</span>
          </div>
          <div>
            <ThermometerVotesMetric />
          </div>
          <div>
            <PulseSurveyVoteMetric />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SurveyMetric;
