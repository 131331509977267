import React from 'react';
import { useCreateBlockNote } from '@blocknote/react';
import '@blocknote/core/style.css';
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from '@nextui-org/react';
import TagInput from '@components/tagInput/tagInput.component';
import { useEffect, useMemo, useState } from 'react';
import TagIcon from '@components/icons/tag.icon';
import PinIcon from '@components/icons/pin.icon';
import DateUtil from '@utils/date';
import Alert from '@components/alert/alert.component';
import PostDTO from '../dto/post.dto';
import PostsRepository from '../../posts/posts.repository';
import PostCategoryDTO from '../dto/postCategory.dto';
import { selectProfile } from '@modules/userModule/auth/auth.reducer';
import { useSelector } from 'react-redux';
import AllowedEntities from '@modules/userModule/permission/enum/allowedEntities.enum';
import AllowedMethods from '@modules/userModule/permission/enum/allowedMethods.enum';
import { useTranslation } from 'react-i18next';
import CustomBlockNote, {
  validateBlockNote,
} from '@components/customBlockNote/customBlockNote.component';
import {
  CustomPartialBlock,
  customSchema,
} from '@components/customBlockNote/customSchema';
import { BlockNoteEditor } from '@blocknote/core';
import FeedUserDTO from '@modules/feed/feedUser/dto/feedUser.dto';

interface CreatePostProps {
  data?: PostDTO;
  refresh?: () => void;
}

const CreatePost = ({ data, refresh }: CreatePostProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<PostCategoryDTO[]>([]);
  const [pinPost, setPinPost] = useState(false);
  const [pinnedUntil, setPinnedUntil] = useState<Date | null>(null);
  const [pinnedDate, setPinnedDate] = useState('');
  const [pinnedHours, setPinnedHours] = useState('');
  const [pinInputError, setPinInputError] = useState<{ [key: string]: string }>(
    {},
  );
  const [blocknoteError, setBlocknoteError] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [postsRepository] = useState(new PostsRepository());
  const user = useSelector(selectProfile);
  const [avatar, setAvatar] = useState<string | undefined>();
  const [initialContent, setInitialContent] = useState<
    CustomPartialBlock[] | 'loading' | null | undefined
  >('loading');
  const [taggedUsers, setTaggedUsers] = useState<FeedUserDTO[]>([]);

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined;
    }
    const placeholders = {
      default: t('user.dashboard.feed.createPost.blocknote.placeholder'),
    };
    if (initialContent === null) {
      return BlockNoteEditor.create({
        schema: customSchema,
        placeholders,
      });
    }
    return BlockNoteEditor.create({
      initialContent,
      schema: customSchema,
      placeholders,
    });
  }, [initialContent]);

  const functionalEditor = useCreateBlockNote({ schema: customSchema });

  const errors = {
    invalidHour: t('user.dashboard.feed.createPost.pin.error.invalidHour'),
    invalidDate: t('user.dashboard.feed.createPost.pin.error.invalidDate'),
    notFutureDate: t('user.dashboard.feed.createPost.pin.error.notFutureDate'),
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (data) {
        if (data.user.avatar) setAvatar(data.user.avatar);
        setPinPost(data.isPinned);
        setPinnedUntil(data.pinnedUntil);
        if (data.pinnedUntil && data.pinnedUntil !== null) {
          setPinnedDate(DateUtil.dateForInput(data.pinnedUntil));
          setPinnedHours(DateUtil.timeForInput(data.pinnedUntil));
        }
        const blocks = await functionalEditor.tryParseHTMLToBlocks(
          data.content,
        );
        setInitialContent(blocks);
        setTags(data.categories);
        setTaggedUsers(data.tagged);
      } else {
        setInitialContent(null);
        if (user?.avatar) setAvatar(user.avatar);
      }
      setIsLoading(false);
    };

    loadData();
  }, []);

  const setTomorrowsDate = () => {
    setPinnedDate(DateUtil.dateForInput(DateUtil.getFutureDate(1, 'day')));
    setPinnedHours(DateUtil.timeForInput(new Date()));
    setPinnedUntil(DateUtil.getFutureDate(1, 'day'));
  };

  const generateFullDate = () => {
    let date = DateUtil.stringToDate(pinnedDate);
    date = DateUtil.stringToHours(date, pinnedHours);
    return date;
  };

  const validatePinFields = () => {
    const newErrors: { [key: string]: string } = {};
    if (!pinnedDate) newErrors.invalidDate = errors.invalidDate;

    if (!pinnedHours) newErrors.invalidHour = errors.invalidHour;

    if (!newErrors.invalidDate && !newErrors.invalidHour) {
      const date = generateFullDate();
      if (!DateUtil.isFutureDate(new Date(), date)) {
        newErrors.notFutureDate = errors.notFutureDate;
      }
    }
    return newErrors;
  };

  useEffect(() => {
    if (pinnedUntil && pinnedUntil !== null) {
      const newErrors = validatePinFields();
      setPinInputError(newErrors);
      if (!Object.keys(newErrors).length) {
        const date = generateFullDate();
        setPinnedUntil(date);
      }
    }
  }, [pinnedDate, pinnedHours]);

  if (editor) editor.onEditorContentChange(() => setBlocknoteError(false));

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    if (!editor) return;
    if (Object.keys(pinInputError).length) return setIsButtonLoading(false);
    if (validateBlockNote(editor)) {
      setBlocknoteError(true);
      setIsButtonLoading(false);
      return;
    }
    const content = await editor.blocksToHTMLLossy(editor.document);
    const cleanContent = content
      .replace(/(<)(.*?)(>)/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
    const post = {
      content,
      cleanContent,
      categories: tags,
      likes: [],
      comments: [],
      isPinned: pinPost,
      pinnedUntil,
      tagged: taggedUsers,
    };
    if (!data) {
      await postsRepository.savePost(post);
      clearInput();
    } else await postsRepository.updatePost(post, data.id);
    setIsButtonLoading(false);
    if (refresh) refresh();
  };

  const clearInput = () => {
    setPinPost(false);
    setTags([]);
    setPinnedUntil(null);
    if (editor) editor.removeBlocks(editor.document);
  };

  const findOrSaveTag = async (name: string): Promise<string> => {
    const results = await postsRepository.getCategory({
      where: { name },
      take: 1,
    });
    if (results[0] && results[0].id) return results[0].id;
    else {
      const resultsCreate = await postsRepository.saveCategory({ name });
      if (resultsCreate.id) return resultsCreate.id;
      return '-1';
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="py-10 flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="space-y-5 py-10 px-5 lg:px-10">
          <div className="flex flex-row gap-5">
            <Avatar
              className="flex-shrink-0 hidden sm:flex"
              showFallback
              isBordered={!data || data.user.email === user?.email}
              color={
                !data || data.user.email === user?.email ? 'primary' : 'default'
              }
              onClick={() => {}}
              src={
                avatar &&
                `https://giveit-system-assets.s3.amazonaws.com/${avatar}`
              }
            />
            <div className="w-full space-y-2">
              {editor && (
                <CustomBlockNote
                  editor={editor}
                  error={blocknoteError}
                  taggedUsers={taggedUsers}
                />
              )}
            </div>
          </div>
          {tags.length ? (
            <div className="space-x-2 space-y-2">
              <span>
                {t('user.dashboard.feed.createPost.hashtag.hashtag')}:{' '}
              </span>
              {tags.map((tag) => (
                <Chip
                  key={tag.id}
                  color="primary"
                  onClose={() => {
                    setTags(tags.filter((t) => t !== tag));
                  }}
                >
                  #{tag.name}
                </Chip>
              ))}
            </div>
          ) : (
            <></>
          )}
          {user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.POST &&
              permission.methods === AllowedMethods.PIN,
          ) &&
            pinPost && (
              <div className="space-x-2">
                {pinPost && !pinnedUntil && (
                  <span className="text-primary dark:text-primary-600">
                    {t('user.dashboard.feed.createPost.pin.pinnedIndefinite')}
                  </span>
                )}
                {pinnedUntil && (
                  <>
                    <span>
                      {t('user.dashboard.feed.createPost.pin.pinnedUntil')}
                    </span>
                    {Object.keys(pinInputError).length ? (
                      <span className="text-danger">
                        {t('user.dashboard.feed.createPost.pin.invalidDate')}
                      </span>
                    ) : (
                      <span className="text-primary dark:text-primary-600">
                        {DateUtil.dateToFullDate(pinnedUntil)}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
        </div>
      )}
      <div className="py-5 px-5 lg:px-10 flex justify-between border-t-2 border-background">
        <div className="flex gap-1">
          <Popover placement="bottom">
            <PopoverTrigger>
              <Button
                variant="light"
                color="primary"
                className="dark:text-primary-600"
              >
                <TagIcon className="w-5" />
                {t('user.dashboard.feed.createPost.hashtag.hashtag')}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              {(titleProps) => (
                <div className="p-2 space-y-2">
                  <p {...titleProps}>
                    {t('user.dashboard.feed.createPost.hashtag.add')}
                  </p>
                  <TagInput
                    tags={tags}
                    setTags={setTags}
                    findOrSaveTag={findOrSaveTag}
                    repository={postsRepository}
                  />
                </div>
              )}
            </PopoverContent>
          </Popover>
          {user?.group.permissions.some(
            (permission) =>
              permission.entity === AllowedEntities.POST &&
              permission.methods === AllowedMethods.PIN,
          ) && (
            <Popover placement="bottom">
              <PopoverTrigger>
                <Button
                  variant="light"
                  color={
                    Object.keys(pinInputError).length ? 'danger' : 'primary'
                  }
                  className="dark:text-primary-600"
                >
                  <PinIcon className="w-5" />
                  {t('user.dashboard.feed.createPost.pin.pin')}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col gap-2 p-2">
                  <span>{t('user.dashboard.feed.createPost.pin.pinPost')}</span>
                  <Checkbox
                    isSelected={pinPost}
                    onValueChange={(e) => {
                      setPinPost(e);
                      if (e) return setPinnedUntil(null);
                      setPinnedUntil(null);
                      setPinInputError({});
                    }}
                    classNames={{ wrapper: 'dark:before:border-content2' }}
                  >
                    {t('user.dashboard.feed.createPost.pin.pin')}
                  </Checkbox>
                  {pinPost && (
                    <>
                      <Checkbox
                        isSelected={pinnedUntil === null}
                        onValueChange={(e) => {
                          if (!e) return setTomorrowsDate();
                          setPinnedUntil(null);
                          setPinInputError({});
                        }}
                        classNames={{ wrapper: 'dark:before:border-content2' }}
                      >
                        {t('user.dashboard.feed.createPost.pin.indefinite')}
                      </Checkbox>
                      {pinnedUntil !== null && pinnedUntil && (
                        <div className="space-y-2">
                          <div className="flex items-center gap-2">
                            <span>
                              {t(
                                'user.dashboard.feed.createPost.pin.pinnedUntil',
                              )}
                            </span>
                            <Input
                              type="date"
                              classNames={{
                                inputWrapper:
                                  'bg-content2 group-data-[focus=true]:bg-content3 data-[hover=true]:bg-content3',
                              }}
                              value={pinnedDate}
                              onValueChange={setPinnedDate}
                              isInvalid={!pinnedDate}
                            />
                            <Input
                              type="time"
                              classNames={{
                                inputWrapper:
                                  'bg-content2 group-data-[focus=true]:bg-content3 data-[hover=true]:bg-content3',
                              }}
                              value={pinnedHours}
                              onValueChange={setPinnedHours}
                              isInvalid={!pinnedHours}
                            />
                          </div>
                          {pinInputError.invalidDate && (
                            <Alert type="danger">
                              {pinInputError.invalidDate}
                            </Alert>
                          )}
                          {pinInputError.invalidHour && (
                            <Alert type="danger">
                              {pinInputError.invalidHour}
                            </Alert>
                          )}
                          {pinInputError.notFutureDate && (
                            <Alert type="danger">
                              {pinInputError.notFutureDate}
                            </Alert>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
        <Button
          color="primary"
          onPress={handleSubmit}
          isDisabled={isLoading}
          isLoading={isButtonLoading}
        >
          {data
            ? t('user.dashboard.feed.createPost.edit')
            : t('user.dashboard.feed.createPost.post')}
        </Button>
      </div>
    </>
  );
};

export default CreatePost;
